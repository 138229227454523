body, html, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

.navbar {
  flex-shrink: 0;
}

.alert ul {
  margin: 0;
}

.loading {
  text-align: center;
  margin: 120px 0;
  color: #bbb;
}

.card-oversized {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}

.form-indicator {
  float: right;
}

.form-indicator-text {
  display: none;
}

.form-indicator:hover .form-indicator-text {
  display: inline;
}

.table-hover tbody tr {
  cursor: pointer;
}

.table-extending, .table-hover tbody tr.table-extending:hover {
  text-align: center;
  color: #bbb;
  background-color: inherit;
  cursor: default;
}

th a {
  cursor: pointer;
}

th > .dropdown-menu {
  margin: 0;
  padding: 0 4px 4px 4px;
  top: -1px;
  left: -5px;
  font-weight: normal;
  min-width: 220px;
}
table .dropdown-title {
  padding: 0.75rem;
  font-weight: bold;
  display: block;
  border-bottom: 2px dotted #e9ecef;
}
table .dropdown-menu .form {
  padding: 0.75em;
}
.dropdown-menu {
  display: block;
}
.btn-group + .form-group {
  margin-top: 1rem;
}
body .rbt .dropdown-menu-justify {
  right: -100px;
}
body .dropdown-item {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
body .rbt .focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: none;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.scrollable th:first-child .dropdown-menu {
  left: -1px;
}
.scrollable th:last-child .dropdown-menu {
  left: auto;
  right: -1px;
}
.scrollable th:last-child .dropdown-title {
  text-align: right;
}
.scrollable td:first-child,
.scrollable th:first-child {
  padding-left: 16px;
}
.scrollable td:last-child,
.scrollable th:last-child {
  padding-right: 16px;
}
.scrollable th,
.scrollable td {
  white-space: nowrap;
}
.scrollable div th,
.scrollable div td {
  white-space: normal;
}

.scrollable {
  overflow: scroll;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.scrollable .table {
  margin: 0;
}
.scrollable .table th {
  border-top-color: transparent;
}

.dropdown-menu-right {
  right: 0;
  left: initial;
}
button {
  cursor: pointer;
}
button:disabled {
  cursor: default;
}

.input-check-aligned {
  margin-top: 38px;
}

.validation-message.invalid-feedback {
  display: block;
  overflow: hidden;
}
.validation-message.invalid-feedback span {
  display: block;
  animation: growAndFadeIn 200ms forwards;
}
@keyframes growAndFadeIn {
  0% {
    transform: translateY(-100%);
    line-height: 0;
  }
  100% {
    transform: translateY(0);
    line-height: 1.5;
  }
}
.campaign-logo {
  float: right;
  max-height: 60px;
}
.clearfix {
  clear: both;
}

.split {
  position: absolute;
  top: 112px;
  left: 0;
  bottom: 0;
  right: 0;
}
.split-grid {
  flex-grow: 1;
  position: relative;
}
.split-detail {
  border-left: 2px solid #e9ecef;
  width: 512px;
  position: relative;
}

.grid-container {
  flex-grow: 1;
  position: relative;
}

.grid-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.text-ultra-muted {
  color: #ddd;
}

.state-transition-icon {
  float: left;
  line-height: 25px;
}
.state-transition-info {
  padding-left: 30px;
}

.state-transition-info-comment {
  background: #f5f5f5;
  padding: 16px;
  margin: 8px 0;
  border-radius: 4px;
}

.state-transition-info a.gray {
  display: none;
}

.state-transition-info:hover a.gray {
  display: block;
}

.inline-button button {
  margin-top: 32px;
  width: 100%;
}

.table-action-cell {
  width: 42px;
  text-align: right;
}
a.gray {
  color: #999;
}
a.gray:hover {
  color: #333;
}
.is-invalid .form-control {
  border-color: #dc3545;
}

thead th.no-border {
  border-top: 0;
}

.form-static .form-group {
  height: 95px;
}

.pre-wrap {
  white-space: pre-wrap;
}

div.show {
  display: block;
}

.full-width {
  width: 100%;
}

.signature {
  width: 100%;
  max-width: 550px;
}

input[type='color'].form-control {
  height: calc(2.25rem + 2px) !important;
}

img.modal-body {
  width: 100%;
}

.table-roles .section th {
  border-top: 2px solid #e9ecef;
}
.table-roles td:first-child {
  padding-left: 16px;
}

.table-roles th.rotate {
  height: 210px;
  white-space: nowrap;
}

.table-roles th.rotate > div {
  transform: rotate(270deg);
  width: 17px;
}

.table-roles th.rotate > div > span {
  padding: 0 4px;
}

.form-roles .custom-checkbox {
  display: flex;
}

span.divider {
  color:#eee;
  margin:0 8px;
}

th.dropdown a {
  text-decoration:none;
  color:inherit;
}

.gentle {
  max-width:600px; margin:0 auto;
}

.input-group .btn-outline-secondary {
  border-color:rgb(206, 212, 218);
}
